import {
  ActivityStatus,
  CurrentUserContextType,
  QActivitySequence,
  QStack,
  QText,
} from '@qualio/ui-components';
import { Parser } from 'html-to-react';

interface CommentThreadProps {
  thread: CommentsRepository.CommentThreadFetched;
  currentUser: CurrentUserContextType;
}

type SuggestionStatus = 'accepted' | 'declined';

export const AcceptedSuggestionThread = ({
  thread,
  currentUser,
}: CommentThreadProps) => {
  const activities = parseSuggestionInThread(thread, currentUser, 'accepted');
  return <QActivitySequence activities={activities} key={thread.id} />;
};

export const DeclinedSuggestionThread = ({
  thread,
  currentUser,
}: CommentThreadProps) => {
  const activities = parseSuggestionInThread(thread, currentUser, 'declined');
  return <QActivitySequence activities={activities} key={thread.id} />;
};

const parseSuggestionInThread = (
  thread: CommentsRepository.CommentThreadFetched,
  currentUser: CurrentUserContextType,
  status: SuggestionStatus,
) => {
  const htmlToReactParser = new Parser();
  const activities = [
    {
      status: 'commented' as ActivityStatus,
      actionLabel: 'suggested',
      fullName: thread.author_full_name ?? 'Unknown user',
      actionTimestamp: thread.suggestion_created_at
        ? currentUser.formatDate(thread.suggestion_created_at, true)
        : 'Unkown time',
      children: getSuggestionAction(thread.suggestion_content),
    },
    ...thread.comments.map((comment, index) => ({
      status: 'commented' as ActivityStatus,
      fullName: comment.attributes.authorFullName ?? comment.user.id,
      actionTimestamp: comment.created_at
        ? currentUser.formatDate(comment.created_at, true)
        : 'Unkown time',
      children: (
        <QStack>
          <QText>{htmlToReactParser.parse(comment.content)}</QText>
        </QStack>
      ),
    })),
    {
      status: (status === 'accepted'
        ? 'approved'
        : 'declined') as ActivityStatus,
      actionLabel:
        status === 'accepted' ? 'accepted suggestion' : 'discarded suggestion',
      fullName: thread.last_updated_by ?? 'Unknown user',
      actionTimestamp: thread.suggestion_deleted_at
        ? currentUser.formatDate(thread.suggestion_deleted_at, true)
        : 'Unkown time',
    },
  ];
  return activities;
};

const getSuggestionAction = (suggestionDataString: string | undefined) => {
  if (!suggestionDataString) {
    return <QText>Suggestion cannot be processed</QText>;
  }
  const match = suggestionDataString.match(/^\*([^*]*)\* ?(.*)$/);
  let action = '';
  let value = 'not provided';
  if (match) {
    action = match[1];
    value = match[2];
  }
  return (
    <QText>
      <b>{action}</b> {value}
    </QText>
  );
};
