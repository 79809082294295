import {
  DataProps,
  QActivity,
  QActivityProps,
  QStack,
  QTarget,
  QText,
  useCurrentUser,
} from '@qualio/ui-components';
import { useCallback } from 'react';
import {
  ActivityLog,
  activityLogDetailMap,
  ActivityLogEntityType,
} from '../../../../api/model/activityLog';
import {
  DocumentFormat,
  DocumentStatus,
  ExternalFileSourceType,
  isDocumentFormat,
} from '../../../../api/model/document';
import { MedtechCompanyUserGroup } from '../../../../api/user';
import {
  ACTIVITY_LOG_UNSUPPORTED_ACTIVITY_TYPE_ERROR,
  logCustomError,
} from '../../../../messages/LogErrorMessages';
import { convertEpochToDate } from '../../../../util/DateUtils';
import { FileDownloadPermissionOptions } from '../../DocumentOverview/DocumentProperties/utils';
import { DocumentStatusCell } from '../../DocumentStatusCell/DocumentStatusCell';
import { FormattedActionHeader } from './FormattedActionHeader';
import { QActivityText } from './QActivityText';
import { QActivityTransition } from './QActivityTransition';
import { QPersonList } from './QPersonList';
import { QTagList } from './QTagList';

export type ActivityLogCardProps = {
  actor: ActivityLog['actor'];
  actionType: ActivityLog['action'];
  entityType: ActivityLog['entity_type'];
  timestamp: ActivityLog['timestamp'];
  data: ActivityLog['data'];
};

const translateValue = (value: number | boolean | string) => {
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  } else if (typeof value === 'number') {
    return `${value} month${value > 1 ? 's' : ''}`;
  } else if (!value) {
    return 'None';
  } else if (isDocumentFormat(value)) {
    return value === DocumentFormat.QualioNativeDocument
      ? 'Qualio Document'
      : 'Uploaded Document';
  } else {
    return value;
  }
};

export const ActivityLogCardWrapper = ({
  actor,
  actionType,
  entityType,
  timestamp,
  data,
}: ActivityLogCardProps) => {
  const currentUser = useCurrentUser();
  const commonProps: Pick<QActivityProps, 'fullName' | 'actionTimestamp'> &
    DataProps = {
    fullName: actor?.full_name ?? 'Qualio',
    actionTimestamp: currentUser.formatDate(
      convertEpochToDate(timestamp),
      true,
    ),
    'data-testid': 'activity-log-card',
  };

  const translatePermissionArray = (groups: MedtechCompanyUserGroup[]) => {
    if (!groups) {
      return FileDownloadPermissionOptions.owner;
    }
    if (groups.includes('read_only')) {
      return FileDownloadPermissionOptions.read_only;
    } else if (groups.includes('normal')) {
      return FileDownloadPermissionOptions.normal;
    } else if (groups.includes('quality')) {
      return FileDownloadPermissionOptions.quality;
    } else {
      return FileDownloadPermissionOptions.owner;
    }
  };

  const getPreviousVersion = (currentVersion: string | undefined) => {
    if (!currentVersion) {
      return 'None';
    }
    const majorVersionNumber = parseInt(currentVersion);
    const previousVersion =
      majorVersionNumber >= 1 ? `${majorVersionNumber}.0` : 'None';

    return previousVersion;
  };

  const getEntityType = (entityType: ActivityLogEntityType) => {
    switch (entityType) {
      case 'change_control':
        return 'Change control';
      case 'document':
      default:
        return 'Document';
    }
  };

  const getExternalSourceLabel = useCallback(
    (source: ExternalFileSourceType) => {
      if (source === 'one_drive') {
        return 'OneDrive file';
      } else {
        return activityLogDetailMap.modified_external_file_upload.entity;
      }
    },
    [],
  );

  switch (actionType) {
    case 'modified_quality_approvers':
    case 'modified_other_approvers':
    case 'modified_reviewers':
    case 'modified_editors':
      return (
        <QActivity
          status="default"
          actionLabel={
            (
              <FormattedActionHeader
                actionType={actionType}
              ></FormattedActionHeader>
            ) as any
          }
          {...commonProps}
        >
          <QStack>
            <QStack
              style={{ flexWrap: 'wrap', rowGap: '8px' }}
              spacing={0}
              direction="row"
            >
              <QPersonList users={data.added_users} status={'added'} />
              <QPersonList users={data.removed_users} status={'removed'} />
            </QStack>
            {data.comment && <QActivityText text={data.comment} />}
          </QStack>
        </QActivity>
      );
    case 'modified_doc_tags':
      return (
        <QActivity
          status="default"
          actionLabel={
            (
              <FormattedActionHeader
                actionType={actionType}
              ></FormattedActionHeader>
            ) as any
          }
          {...commonProps}
        >
          <QStack
            style={{ flexWrap: 'wrap', rowGap: '8px' }}
            spacing={0}
            direction="row"
          >
            <QTagList tagNames={data.added_tags} action={'added'} />
            <QTagList tagNames={data.removed_tags} action={'removed'} />
          </QStack>
        </QActivity>
      );
    case 'approved':
    case 'declined':
    case 'reviewed':
      return (
        <QActivity status={actionType} {...commonProps}>
          {data.comment && <QActivityText text={data.comment} />}
          <QTarget>v{data.document_version}</QTarget>
        </QActivity>
      );
    case 'modified_doc_title':
    case 'modified_doc_review_period':
    case 'modified_doc_training_required':
    case 'modified_doc_effective_on_approval':
    case 'modified_doc_format':
    case 'modified_upload_file':
      return (
        <QActivity
          status="default"
          actionLabel={
            (
              <FormattedActionHeader
                actionType={actionType}
              ></FormattedActionHeader>
            ) as any
          }
          {...commonProps}
        >
          <QActivityTransition
            oldValue={translateValue(data.old_value)}
            newValue={translateValue(data.new_value)}
          />
        </QActivity>
      );
    case 'modified_file_download_permission_group':
      return (
        <QActivity
          status="default"
          actionLabel={
            (
              <FormattedActionHeader
                actionType={actionType}
              ></FormattedActionHeader>
            ) as any
          }
          {...commonProps}
        >
          <QActivityTransition
            oldValue={translatePermissionArray(data.old_value)}
            newValue={translatePermissionArray(data.new_value)}
          />
        </QActivity>
      );
    case 'upgraded_document_template':
      return (
        <QActivity
          status="default"
          actionLabel={
            (
              <FormattedActionHeader
                actionType={actionType}
              ></FormattedActionHeader>
            ) as any
          }
          {...commonProps}
        >
          <QActivityTransition
            oldValue={`Template version ${data.previous_template_version}`}
            newValue={`Template version ${data.new_template_version}`}
          />
        </QActivity>
      );
    case 'modified_doc_owner':
      return (
        <QActivity
          status="default"
          actionLabel={
            (
              <FormattedActionHeader
                actionType={actionType}
              ></FormattedActionHeader>
            ) as any
          }
          {...commonProps}
        >
          <QActivityTransition
            oldValue={data.removed_users[0].full_name}
            newValue={data.added_users[0].full_name}
          />
        </QActivity>
      );
    case 'changed_status':
      return (
        <QActivity status="changedStatus" {...commonProps}>
          <QTarget>v{data.document_version}</QTarget>
          <QStack>
            <QActivityTransition
              oldValue={
                <DocumentStatusCell
                  statusId={data.previous_status as DocumentStatus}
                />
              }
              newValue={
                <DocumentStatusCell
                  statusId={data.new_status as DocumentStatus}
                />
              }
            />
            {data.comment && <QActivityText text={data.comment} />}
          </QStack>
        </QActivity>
      );
    case 'created_new_draft':
      return (
        <QActivity
          status="versioned"
          {...commonProps}
          actionLabel={
            (
              <FormattedActionHeader
                actionType={actionType}
              ></FormattedActionHeader>
            ) as any
          }
        >
          <QStack>
            <QActivityTransition
              oldValue={getPreviousVersion(data.document_version)}
              newValue={data.document_version}
            />
          </QStack>
        </QActivity>
      );
    case 'edited_sections':
      return (
        <QActivity status="edited" {...commonProps}>
          <QTarget>{getEntityType(entityType)}</QTarget>
          <QStack>
            <QStack direction="row" spacing={1}>
              <QText>Edited</QText>
              <QActivityText text={data.sections_edited[0]} weight="semibold" />
            </QStack>
          </QStack>
        </QActivity>
      );
    case 'modified_external_file_upload': {
      const sourceLabel = getExternalSourceLabel(data.source);
      const hasFilenameChange = data.old_value !== data.new_value;
      return (
        <QActivity status="default" {...commonProps} actionLabel="updated">
          <QTarget>{sourceLabel}</QTarget>
          {hasFilenameChange && (
            <QStack>
              <QActivityTransition
                oldValue={translateValue(data.old_value)}
                newValue={translateValue(data.new_value)}
              />
            </QStack>
          )}
        </QActivity>
      );
    }
    default:
      logCustomError(ACTIVITY_LOG_UNSUPPORTED_ACTIVITY_TYPE_ERROR, {
        error: {
          message: 'Unknown activity log action type',
          data: { actionType, actor, timestamp, data },
        },
      });
      return null;
  }
};
