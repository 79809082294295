import { useContext } from 'react';
import { useMutation } from 'react-query';
import { sanitizeDocumentSections } from '../../../../util/DocumentSectionSanitizer';
import { DocumentOverviewContext } from '../../DocumentOverview/Context';
import { TrainingAiAssessmentApi } from '../api/trainingAi';

export const useGenerateTrainingAssessment = (aiSanitizeHtml: boolean) => {
  const { qualioDocument } = useContext(DocumentOverviewContext);

  return useMutation({
    mutationFn: () =>
      TrainingAiAssessmentApi.generateTrainingAssessment(
        qualioDocument.title,
        aiSanitizeHtml
          ? sanitizeDocumentSections(qualioDocument.sections)
          : qualioDocument.sections,
      ),
  });
};
