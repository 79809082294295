import {
  QButton,
  useCurrentUser,
  useToastProvider,
} from '@qualio/ui-components';
import { useContext, useState } from 'react';
import { documentApi } from '../../../../../api/document';
import { DocumentStatus } from '../../../../../api/model/document';
import { ButtonGroupContext } from '../../../Contexts/ButtonGroupContext';
import { DocumentOverviewContext } from '../../Context';
import { shouldRenderSendForApprovalButton } from '../../RenderLogic';

export const SendForApprovalButton = () => {
  const currentUser = useCurrentUser();
  const { qualioDocument, refetchDocument, currentEditorMode } = useContext(
    DocumentOverviewContext,
  );
  const { isDisabled, setDisabled } = useContext(ButtonGroupContext);
  const [isLoading, setIsLoading] = useState(false);
  const { showToast } = useToastProvider();

  const commonToastProps = {
    id: 'send-for-approval-toast',
    replace: true,
  };

  const DEFAULT_SEND_FOR_APPROVAL_ERROR_MESSAGE =
    'Failed to send for approval. Please try again.';

  const validateQualityApprovers = () => {
    const { audit_groups: { approver_group } = {} } = qualioDocument;
    const hasQualityApprover = approver_group?.some(({ is_qm }) => is_qm);
    if (!hasQualityApprover) {
      throw new Error('This document has no Quality approvers.');
    }
  };

  const handleSendForApproval = async () => {
    try {
      validateQualityApprovers();
      setDisabled(true, setIsLoading);
      await documentApi
        .changeStatus(qualioDocument.id, {
          status: DocumentStatus.For_Approval,
        })
        .then(() => {
          setDisabled(false, setIsLoading);
          refetchDocument();
          showToast({
            ...commonToastProps,
            description: 'Successfully sent document for approval.',
            status: 'success',
            title: 'Sent for approval!',
          });
        })
        .catch((e) => {
          setDisabled(false, setIsLoading);
          const failureMessage =
            e.response?.data?.message ||
            DEFAULT_SEND_FOR_APPROVAL_ERROR_MESSAGE;
          throw new Error(failureMessage);
        });
    } catch (e: unknown) {
      const message =
        e instanceof Error
          ? e.message
          : DEFAULT_SEND_FOR_APPROVAL_ERROR_MESSAGE;
      showToast({
        ...commonToastProps,
        status: 'error',
        title: 'Error',
        description: message,
      });
    }
  };

  if (
    !shouldRenderSendForApprovalButton(
      qualioDocument,
      currentUser,
      currentEditorMode,
    )
  ) {
    return null;
  }

  return (
    <QButton
      isLoading={isLoading}
      isDisabled={isDisabled}
      onClick={handleSendForApproval}
      data-testid="doc-overview-send-for-approval-button"
      data-cy="doc-overview-send-for-approval-button"
    >
      Send for approval
    </QButton>
  );
};
