import {
  QButton,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QStack,
  QText,
} from '@qualio/ui-components';
import { useMemo } from 'react';
import { SuppressedAssignedTraineesToDocuments } from '../../../../../../../api/document';
import { MedtechUserV2 } from '../../../../../../../api/user';

type SuppressedAssignmentsModalProps = {
  suppressedAssignments?: SuppressedAssignedTraineesToDocuments[];
  onClose: () => void;
};

const getElement = (
  code: string,
  reason: string,
  suppressedAssignments?: Pick<MedtechUserV2, 'email' | 'full_name' | 'id'>[],
) =>
  suppressedAssignments?.map((trainee) => (
    <QStack key={trainee.id} direction="row">
      <QText weight="semibold">{code}</QText>
      <QText>--</QText>
      <QText>{trainee.full_name}</QText>
      <QText>--</QText>
      <i>{reason}</i>
    </QStack>
  ));

export const SuppressedAssignmentsModal = ({
  suppressedAssignments,
  onClose,
}: SuppressedAssignmentsModalProps) => {
  const suppressedDescriptions = useMemo(
    () =>
      suppressedAssignments?.map((assignment) => {
        const alreadyAssigned = getElement(
          assignment.code,
          'Trainee already',
          assignment.suppressed.current_trainee,
        );
        const noAccessAssigned = getElement(
          assignment.code,
          'Unable to access the document due to visibility tags used.',
          assignment.suppressed.document_inaccessible,
        );
        return [...(alreadyAssigned ?? []), ...(noAccessAssigned ?? [])];
      }),
    [suppressedAssignments],
  );

  return (
    <QModal
      onClose={onClose}
      isOpen={!!suppressedAssignments}
      data-testid="suppressed-training-assignment-modal"
      size="xl"
    >
      <QModalHeader>
        <QText>Some trainees have not been assigned</QText>
      </QModalHeader>
      <QModalBody>
        <QStack spacing={6}>
          <QText>
            We were unable to assign trainees to the following documents. The
            trainee has already been assigned, or they do not have permission to
            access the document:
          </QText>
          <QStack spacing={3}>{suppressedDescriptions}</QStack>
        </QStack>
      </QModalBody>
      <QModalActions>
        <QButton
          data-testid="close-suppressed-assignments-modal"
          onClick={onClose}
        >
          Confirm
        </QButton>
      </QModalActions>
    </QModal>
  );
};
