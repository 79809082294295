import { Parser } from 'html-to-react';
import { useEffect, useState } from 'react';
import { DocumentSectionRevision } from '../../../api/model/document';
import { DocumentPresentationUtils } from '../../../util/DocumentPresentationUtils';
import styles from '../DocumentContentEditor/DocumentContentEditor.module.css';

import { useCurrentUser } from '@qualio/ui-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { extractAttachmentIdFromHref } from '../../../util/HTMLElementUtils';
import { InlineAttachmentPreviewModal } from '../InlineAttachmentPreview/InlineAttachmentPreviewModal';

interface DocumentCompareVersionsViewerProps {
  sectionRevisions: DocumentSectionRevision[];
}

export const DocumentCompareVersionsViewer = ({
  sectionRevisions,
}: DocumentCompareVersionsViewerProps) => {
  const currentUser = useCurrentUser();
  const { santitizeHtmlView, compareAgainstNewDiff } = useFlags();
  const htmlToReactParser = new Parser();
  const sanitize = compareAgainstNewDiff ? false : santitizeHtmlView;
  const processedDocumentContent =
    DocumentPresentationUtils.determineHtmlContentFromSections(
      sectionRevisions,
      false, // we strip the collab data before diffing, so there shouldn't be any html to strip
      false,
      false,
      true,
      sanitize,
      true,
    );
  const [attachmentForPreview, setAttachmentForPreview] = useState<string>();
  const [contentElement, setContentElement] = useState<Element | null>();
  const htmlContent = htmlToReactParser.parse(processedDocumentContent);

  useEffect(() => {
    setContentElement(document.querySelector('#documentHTMLViewerContent'));
    if (contentElement) {
      const anchors = contentElement.getElementsByTagName('a');
      for (const anchor of anchors) {
        anchor.addEventListener('click', (evt) => {
          evt.preventDefault();
          const href = (evt.currentTarget as any).href;
          if (!href) {
            return;
          }
          const attachmentId = extractAttachmentIdFromHref(href);
          if (attachmentId) {
            // open preview modal for links on inline attachments
            setAttachmentForPreview(attachmentId);
          } else {
            // open links that are not inline attachments in a new tab
            window.open(href, '_blank');
          }
        });
      }
    }
  }, [contentElement]);

  return (
    <div
      className={`${styles['document-editor__document__content-html-view-container']} ${styles['document-editor__document__content-container__show']}`}
    >
      <div
        id="documentHTMLViewerContent"
        data-testid="documentHTMLViewerContent"
        className={`${styles['document-editor__document__section-content']} document`}
      >
        {htmlContent}
      </div>
      {attachmentForPreview && (
        <InlineAttachmentPreviewModal
          currentUser={currentUser}
          attachmentId={attachmentForPreview}
          onClose={() => setAttachmentForPreview(undefined)}
        />
      )}
    </div>
  );
};
