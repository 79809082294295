import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import {
  QBadge,
  QBodyLayout,
  QBox,
  QButtonGroup,
  QHeader,
  QHorizontalMetadata,
  QLastModifiedMetadata,
  QOpenPropertiesPanelButton,
  QOwnerMetadata,
  QPageLoader,
  QStack,
  QTab,
  QTabList,
  QTabPanel,
  QTabPanels,
  QTabs,
  QTag,
  QTitle,
  QVersionMetadata,
  useCurrentUser,
  useToastProvider,
} from '@qualio/ui-components';

import { documentApi } from '../../../api/document';
import { GroupApi } from '../../../api/group';
import { DocumentStatus } from '../../../api/model/document';
import { TagApi } from '../../../api/tag';
import { tokenApi } from '../../../api/token';
import { userApi } from '../../../api/user';

import {
  currentUserHasAccessToDocument,
  getUserCommentsPermission,
  isBasicUserInAccount,
} from '../../../util/CurrentUser';
import {
  convertApiChangeControlToViewableInstance,
  convertApiDocumentToViewableInstance,
} from '../../../util/DocumentApiUtils';
import {
  EditorMode,
  mapEditorModeToIntentString,
  mapIntentStringToEditorMode,
} from '../../../util/EditModeUtils';
import { DocumentStatusIdToStatusConfigMap } from '../../../util/WorkspaceUtils';

import { DocumentContainer } from '../DocumentContainer/index';
import { DocumentStatusBanner } from '../DocumentStatusBanner/index';
import { TrainingAssessmentContainer } from '../TrainingAssessment/TrainingAssessmentContainer';
import {
  ApprovalControls,
  AutoSaveNotification,
  ButtonsContextWrapper,
  CreateNewDraftButton,
  DisplayVersionSelect,
  EditButton,
  EditSuggestToggle,
  ExportDocumentButton,
  MakeEffectiveButton,
  MarkAsReviewedButton,
  MoreActionsButton,
  PeriodicReviewButton,
  RevertToDraftButton,
  SaveAndExitButton,
  SendForApprovalButton,
  SendForReviewButton,
  SuggestButton,
  UndoDeleteButton,
} from './Buttons';
import { ChangeControlDisplay } from './ChangeControl/ChangeControl';
import { DocumentProperties } from './DocumentProperties';
import { DocumentTrainingButton, TrainingAlert } from './DocumentTraining';
import { DocumentVariantRenderer } from './DocumentVariantRenderer/DocumentVariantRenderer';

import {
  DocumentOverviewContext,
  DocumentOverviewContextType,
  EditorStatus,
  EditorStatusContext,
  EditorStatusContextType,
} from './Context';
import {
  canUserEditDocument,
  canUserSuggestOnDocument,
  shouldRenderCCTab,
  shouldRenderCompareAgainst,
  shouldRenderTrainingTab,
  sortVersions,
} from './RenderLogic';

import { VersionSelect } from '../DocumentCompareVersions';

import { changeControlApi } from '../../../api/changeControl';
import { useBodyBackground } from '../../../hooks/BodyBackground';
import { initialiseGlobalEditedSections } from '../../../util/ActivityLogUtils';
import { redirect } from '../../../util/PageUtils';
import { BannerPlaceholder } from '../BannerPlaceholder';
import { NotFound } from '../NotFound';
import { ToolbarWrapper } from '../ToolbarWrapper/ToolbarWrapper';
import styles from './DocumentOverview.module.css';
import { useNextVersionDetails } from './hooks/index';

const TabPanelWrapper: FC = ({ children }) => (
  <QBox paddingTop="24px">{children}</QBox>
);

export enum OverviewTabs {
  DOCUMENT = 'document',
  CHANGE_CONTROL = 'change-control',
  TRAINING_ASSESSMENT = 'training',
}

export const DocumentOverview = () => {
  useBodyBackground('document-editor-overview-refresh', true);
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const intent = searchParams.get('intent');
  const requestedDocVersion = searchParams.get('version');
  const requestedTab = searchParams.get('tab');
  const currentUser = useCurrentUser();
  const { showToast } = useToastProvider();
  const [commentsPermission, setCommentsPermission] = useState<number>();
  const [requestedDocumentVersion, setRequestedDocumentVersion] = useState<
    string | null
  >(requestedDocVersion);
  const location = useLocation();
  const navigate = useNavigate();
  const [docEditorStatus, setDocEditorStatus] = useState<EditorStatus>(
    EditorStatus.STABLE,
  );
  const [ccEditorStatus, setCCEditorStatus] = useState<EditorStatus>(
    EditorStatus.STABLE,
  );
  const [trainingAssessmentStatus, setTrainingAssessmentStatus] =
    useState<EditorStatus>(EditorStatus.STABLE);

  const tabKeyInDisplayOrder = [
    OverviewTabs.DOCUMENT,
    OverviewTabs.CHANGE_CONTROL,
    OverviewTabs.TRAINING_ASSESSMENT,
  ];

  const { realTimeCollaboration } = useFlags();

  useEffect(() => {
    setRequestedDocumentVersion(requestedDocVersion);
  }, [requestedDocVersion]);

  const [currentTabIndex, setCurrentTabIndex] = useState<number>(
    requestedTab
      ? tabKeyInDisplayOrder.indexOf(requestedTab as OverviewTabs)
      : tabKeyInDisplayOrder.indexOf(OverviewTabs.DOCUMENT),
  );

  const handleTabClick = (tabIndex: number) => {
    setCurrentTabIndex(tabIndex);
    searchParams.set('tab', tabKeyInDisplayOrder[tabIndex]);
    setSearchParams(searchParams);
  };

  const loadDocument = useCallback(async () => {
    if (!id) {
      return;
    }
    try {
      const fetchedDocument = await documentApi.fetchByIdOrVersion(
        id,
        requestedDocVersion ?? '',
        1,
        1,
      );
      document.title =
        fetchedDocument.code + ' ' + fetchedDocument.title + ' - Qualio';
      return convertApiDocumentToViewableInstance(fetchedDocument);
    } catch (e: any) {
      if (
        e.response?.data?.slug === 'document-access-restricted-or-not-found'
      ) {
        navigate('/missing');
      } else {
        showToast({
          id: 'fetch-document-failure',
          status: 'error',
          title: 'Failed to fetch document',
          description:
            'An error occurred when fetching the document data. Please try again or contact support.',
          replace: true,
        });
      }
    }
  }, [id, requestedDocVersion, showToast, navigate]);

  const {
    isLoading: isLoadingDocument,
    isFetching: isFetchingDocument,
    data: qualioDocument,
    refetch: refetchDocument,
  } = useQuery({
    queryKey: ['newDocument', id, requestedDocumentVersion],
    queryFn: loadDocument,
    refetchOnWindowFocus: false,
  });

  const { data: nextVersion } = useNextVersionDetails(
    qualioDocument,
    currentUser.companyId,
  );

  const [editorMode, setEditorMode] = useState<EditorMode>(EditorMode.VIEW);

  useEffect(() => {
    initialiseGlobalEditedSections();
  }, []);

  const updateEditorMode = useCallback(
    (mode: EditorMode) => {
      searchParams.set('intent', mapEditorModeToIntentString(mode));
      setSearchParams(searchParams);
      setEditorMode(mode);
    },
    // searchParams here will cause editor crashes if the user tabs during editor startup
    // we instead use ID to ensure that at least the searchparams state is updated between document changes on the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setEditorMode, id, requestedDocVersion],
  );

  useEffect(() => {
    if (qualioDocument?.status_id && intent) {
      const canEdit = canUserEditDocument(qualioDocument, currentUser);
      const canSuggest = canUserSuggestOnDocument(qualioDocument, currentUser);
      const mode = mapIntentStringToEditorMode(intent);

      switch (true) {
        case intent === 'edit' && canEdit:
        case intent === 'suggest' && canSuggest:
          updateEditorMode(mode);
          break;
        default:
          updateEditorMode(EditorMode.VIEW);
      }
    }
  }, [currentUser, intent, qualioDocument, updateEditorMode]);

  const { data: periodicReview, refetch: refetchPeriodicReview } = useQuery({
    queryKey: ['periodicReview', id],
    queryFn: () =>
      documentApi.getPeriodicReviewByDocumentId(
        currentUser,
        qualioDocument!.id,
      ),
    refetchOnWindowFocus: false,
    enabled: qualioDocument?.status_id === DocumentStatus.Effective,
  });

  const {
    data: versions = [],
    isLoading: isLoadingVersions,
    isFetching: isFetchingVersions,
    refetch: refetchVersions,
  } = useQuery({
    queryKey: ['versions', id],
    queryFn: () => documentApi.getDocumentVersionsByDocumentId(+id!),
    refetchOnWindowFocus: false,
    enabled: !!qualioDocument,
  });

  const { data: users = [] } = useQuery({
    queryKey: ['users'],
    queryFn: () => userApi.fetchUsers(currentUser.companyId),
    refetchOnWindowFocus: false,
  });

  const { data: tags = [] } = useQuery({
    queryKey: ['tags', currentUser.userId],
    queryFn: () => TagApi.fetchTags(currentUser),
    refetchOnWindowFocus: false,
    enabled: !!users.length,
  });

  const { data: groups = [] } = useQuery({
    queryKey: ['groups', currentUser.userId],
    queryFn: () => GroupApi.fetchGroups(),
    refetchOnWindowFocus: false,
  });

  const refetchDocumentCallback = useCallback(
    (fetchLatestVersion = true) => {
      setEditorMode(EditorMode.VIEW);

      if (fetchLatestVersion) {
        setRequestedDocumentVersion(null);
      }
      void refetchDocument();
      void refetchVersions();
    },
    [refetchDocument, refetchVersions],
  );

  const loadCollaborationToken = async () => {
    const currentCommentPermissions = getUserCommentsPermission(
      currentUser,
      qualioDocument as any,
    );
    setCommentsPermission(currentCommentPermissions);
    return tokenApi.fetch({ comments: currentCommentPermissions });
  };
  const {
    data: collaborationToken,
    isFetching: isFetchingToken,
    isLoading: isLoadingToken,
  } = useQuery({
    queryKey: [
      'collabToken',
      id,
      qualioDocument?.status_id,
      qualioDocument?.owner,
    ],
    queryFn: loadCollaborationToken,
    refetchOnWindowFocus: false,
    retry: 2,
    onError: () => {
      showToast({
        id: 'fetch-collab-token-failures',
        status: 'error',
        title: 'Authentication failed',
        description:
          'An error occurred when during authentication, redirecting to login',
        replace: true,
      });
      redirect();
    },
    enabled: !!qualioDocument,
  });

  const fetchChangeControl = async () => {
    if (!id) {
      return null;
    }
    const changeControlResponse = await changeControlApi.fetchChangeControl(
      Number(id),
      currentUser.companyId,
    );
    if (changeControlResponse.length > 0) {
      const changeControl = changeControlResponse[0];
      return convertApiChangeControlToViewableInstance(changeControl);
    } else {
      return null;
    }
  };

  const {
    data: changeControl,
    refetch: refetchChangeControl,
    isLoading: isLoadingChangeControl,
    isFetching: isFetchingChangeControl,
  } = useQuery({
    queryFn: fetchChangeControl,
    queryKey: ['documentChangeControl', [id]],
    refetchOnWindowFocus: false,
    retry: false,
  });

  const displayVersion = useMemo(() => {
    return `${qualioDocument?.major_version}.${qualioDocument?.minor_version}`;
  }, [qualioDocument]);

  const documentOverviewContext: DocumentOverviewContextType = useMemo(
    () => ({
      qualioDocument: qualioDocument!,
      changeControl: changeControl!,
      currentEditorMode: editorMode,
      commentsPermission: commentsPermission!,
      collaborationToken: collaborationToken!,
      refetchDocument: refetchDocumentCallback,
      updateEditorMode: updateEditorMode,
    }),
    [
      editorMode,
      qualioDocument,
      changeControl,
      refetchDocumentCallback,
      commentsPermission,
      collaborationToken,
      updateEditorMode,
    ],
  );

  const editorStatusContext: EditorStatusContextType = useMemo(
    () => ({
      docEditorStatus,
      ccEditorStatus,
      trainingAssessmentStatus,
      setCCEditorStatus,
      setDocEditorStatus,
      setTrainingAssessmentStatus,
    }),
    [
      docEditorStatus,
      ccEditorStatus,
      trainingAssessmentStatus,
      setCCEditorStatus,
      setDocEditorStatus,
      setTrainingAssessmentStatus,
    ],
  );

  const statusProps = useMemo(() => {
    if (qualioDocument?.status_id) {
      return DocumentStatusIdToStatusConfigMap[
        qualioDocument.status_id as DocumentStatus
      ];
    }
  }, [qualioDocument?.status_id]);

  const sortedVersions = useMemo(
    () => (versions ? sortVersions(versions) : []),
    [versions],
  );

  const latestVersion = useMemo(() => {
    return sortedVersions.length
      ? sortedVersions[sortedVersions.length - 1]
      : null;
  }, [sortedVersions]);

  const handleDisplayVersionOptionChange = useCallback(
    (isLatest: boolean, docId: number, version: string) => {
      const isMajorVersionSelected =
        versions.find(
          ({ version: selectedVersionStr }) => selectedVersionStr === version,
        )?.minor === '0';

      const isLatestOrMajorVersion = isLatest || isMajorVersionSelected;

      if (isLatestOrMajorVersion) {
        searchParams.delete('version');
        setSearchParams(searchParams);
        setRequestedDocumentVersion(null);
      } else {
        searchParams.set('version', version);
        setRequestedDocumentVersion(version);
        setSearchParams(searchParams);
      }

      if (docId.toString() !== id) {
        const newPath = location.pathname.replace(
          id as string,
          docId.toString(),
        );
        const params = new URLSearchParams();
        params.set('tab', requestedTab ?? OverviewTabs.DOCUMENT);
        if (!isLatestOrMajorVersion) {
          params.set('version', version);
        }
        navigate({
          pathname: newPath,
          search: createSearchParams(params).toString(),
        });
      }
    },
    [
      id,
      location.pathname,
      navigate,
      requestedTab,
      searchParams,
      setSearchParams,
      versions,
    ],
  );

  const handleCompareVersionsOptionChange = useCallback(
    (version: string) => {
      if (version !== '') {
        navigate({
          pathname: location.pathname + `/compare/${version}`,
          search: createSearchParams({
            version: `${qualioDocument?.major_version}.${qualioDocument?.minor_version}`,
          }).toString(),
        });
      }
    },
    [navigate, location.pathname, qualioDocument],
  );

  const widthRef = useRef<HTMLDivElement>(null);

  if (
    !qualioDocument ||
    isLoadingDocument ||
    isFetchingDocument ||
    isLoadingToken ||
    isFetchingToken ||
    isLoadingVersions ||
    isFetchingVersions ||
    isFetchingChangeControl ||
    isLoadingChangeControl
  ) {
    return (
      <>
        <BannerPlaceholder />
        <DocumentContainer>
          <QPageLoader />
        </DocumentContainer>
      </>
    );
  }

  if (!currentUserHasAccessToDocument(currentUser, qualioDocument)) {
    return <NotFound />;
  }

  return (
    <DocumentOverviewContext.Provider value={documentOverviewContext}>
      <EditorStatusContext.Provider value={editorStatusContext}>
        <DocumentStatusBanner />
        <DocumentContainer>
          <QBodyLayout.Default>
            <QHeader showDivider={false}>
              <QTitle>
                {qualioDocument.title}{' '}
                <QBadge data-cy="document-code">{qualioDocument.code}</QBadge>
              </QTitle>
              <QHorizontalMetadata>
                <QTag
                  variantColor={statusProps?.color}
                  data-cy="document-status-label"
                >
                  {statusProps?.label}
                </QTag>
                <QVersionMetadata
                  data-cy="document-version"
                  value={
                    qualioDocument.major_version +
                    '.' +
                    qualioDocument.minor_version
                  }
                />
                <QOwnerMetadata
                  data-cy="document-owner"
                  value={qualioDocument.owner.full_name}
                />
                <QLastModifiedMetadata
                  data-cy="document-last-modified"
                  value={new Date(qualioDocument.modified_time * 1000)}
                />
              </QHorizontalMetadata>
              <QButtonGroup>
                <ButtonsContextWrapper>
                  <AutoSaveNotification />
                  <ExportDocumentButton />
                  <SuggestButton setEditorMode={updateEditorMode} />
                  <EditButton setEditorMode={updateEditorMode} />
                  <SaveAndExitButton setEditorMode={updateEditorMode} />
                  <UndoDeleteButton />
                  <CreateNewDraftButton periodicReview={periodicReview} />
                  <MakeEffectiveButton />
                  <SendForReviewButton />
                  <PeriodicReviewButton
                    periodicReview={periodicReview}
                    users={users}
                    refetchPeriodicReview={refetchPeriodicReview}
                    nextVersion={nextVersion}
                  />
                  <RevertToDraftButton />
                  <SendForApprovalButton />
                  <MarkAsReviewedButton />
                  <ApprovalControls
                    periodicReview={periodicReview}
                    refetchPeriodicReview={refetchPeriodicReview}
                    versions={versions}
                  />
                  <QBox zIndex={3}>
                    <MoreActionsButton users={users} tags={tags} />
                  </QBox>
                </ButtonsContextWrapper>
                <QOpenPropertiesPanelButton />
              </QButtonGroup>
            </QHeader>
            <QStack direction="row">
              <QBox width={'100%'}>
                <QTabs
                  onChange={handleTabClick}
                  index={currentTabIndex}
                  variant="enclosed"
                >
                  <QTabList
                    data-cy={'tab-and-version-select'}
                    minWidth={'831px'}
                  >
                    <QBox className="cc-presence-list"></QBox>
                    <QTab data-testid={'document-tab'} data-cy={'document-tab'}>
                      Document
                    </QTab>
                    {shouldRenderCCTab(qualioDocument) && (
                      <QTab
                        data-testid={'change-control-tab'}
                        data-cy={'change-control-tab'}
                      >
                        Change control
                      </QTab>
                    )}
                    {shouldRenderTrainingTab(qualioDocument, currentUser) && (
                      <QTab
                        data-testid={'training-tab'}
                        data-cy={'training-tab'}
                      >
                        Training assessment
                      </QTab>
                    )}
                    <EditSuggestToggle setEditorMode={updateEditorMode} />
                    {editorMode === EditorMode.VIEW && (
                      <QStack isInline ml="auto" spacing={2} zIndex={2}>
                        {!isBasicUserInAccount(currentUser) && (
                          <DisplayVersionSelect
                            latestVersion={
                              latestVersion
                                ? latestVersion.version
                                : latestVersion
                            }
                            handleOptionChange={
                              handleDisplayVersionOptionChange
                            }
                            version={displayVersion}
                            versions={versions}
                          />
                        )}
                        {shouldRenderCompareAgainst(
                          qualioDocument,
                          currentUser,
                        ) && (
                          <VersionSelect
                            LHSVersion={displayVersion}
                            latestVersion={
                              sortedVersions[sortedVersions.length - 1].version
                            }
                            variant="RHS"
                            handleOptionChange={
                              handleCompareVersionsOptionChange
                            }
                            version=""
                            allVersions={versions}
                          />
                        )}
                      </QStack>
                    )}

                    {realTimeCollaboration ? (
                      <QBox
                        ml={
                          editorMode === EditorMode.VIEW ||
                          qualioDocument.status_id === DocumentStatus.Draft
                            ? '2px'
                            : 'auto'
                        }
                        className="doc-overview-presence-list"
                      ></QBox>
                    ) : (
                      <QBox
                        className="doc-overview-presence-list"
                        style={{ display: 'none' }}
                      ></QBox>
                    )}
                  </QTabList>
                  <QTabPanels
                    id="document-overview-tab-panels"
                    className={`${styles['document-overview-tabs']}`}
                  >
                    <QTabPanel>
                      <TabPanelWrapper>
                        <ToolbarWrapper
                          widthRef={widthRef}
                          className={`${styles['document-overview__toolbar']}`}
                        />
                        <TrainingAlert />
                        <DocumentVariantRenderer ref={widthRef} />
                        <DocumentTrainingButton />
                      </TabPanelWrapper>
                    </QTabPanel>
                    {shouldRenderCCTab(qualioDocument) && (
                      <QTabPanel>
                        <TabPanelWrapper>
                          <ChangeControlDisplay
                            changeControl={changeControl}
                            refetchChangeControl={refetchChangeControl}
                          />
                        </TabPanelWrapper>
                      </QTabPanel>
                    )}
                    {shouldRenderTrainingTab(qualioDocument, currentUser) && (
                      <QTabPanel>
                        <TabPanelWrapper>
                          <QBox
                            className={`${styles['document-overview__trainingContainer']}`}
                          >
                            <TrainingAssessmentContainer />
                          </QBox>
                        </TabPanelWrapper>
                      </QTabPanel>
                    )}
                  </QTabPanels>
                </QTabs>
              </QBox>
              <DocumentProperties
                tags={tags}
                versions={versions}
                changeControl={{
                  changeId: changeControl?.id,
                  changeControlReference:
                    changeControl?.change_control_reference,
                }}
                groups={groups}
                users={users}
                periodicReview={periodicReview}
              />
            </QStack>
          </QBodyLayout.Default>
        </DocumentContainer>
      </EditorStatusContext.Provider>
    </DocumentOverviewContext.Provider>
  );
};
