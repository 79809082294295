import { useFlags } from 'launchdarkly-react-client-sdk';
import { SyntheticEvent, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import {
  QButton,
  QFormControl,
  QInput,
  QMenuButton,
  QMenuItem,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QRadio,
  QRadioGroup,
  QSelect,
  QSelectPlaceholder,
  QStack,
  QText,
  useToastProvider,
} from '@qualio/ui-components';
import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';

import { changeControlApi } from '../../../api/changeControl';
import { documentApi } from '../../../api/document';
import { DocumentFormat } from '../../../api/model/document';
import templateApi, { Template } from '../../../api/templateApi';

import { sortByLabel } from '../../../util/SelectionOptionUtils';

import {
  ChangeControlDTO,
  DocumentPropertiesDTO,
  PropertiesForm,
} from '../../../types/DocumentCreate';

export const BULK_UPLOAD_PATH = 'upload';

export const NewDocumentButton = ({
  currentUser,
}: {
  currentUser: CurrentUser;
}) => {
  const { bulkFileDocumentUpload, qualioFrontendRefresh } = useFlags();
  const { showToast } = useToastProvider();

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const [templateOptions, setTemplateOptions] = useState<any[]>([]);

  const [changeControlTemplateId, setChangeControlTemplateId] = useState<
    number | null
  >(null);
  const [changeControl, setChangeControl] = useState<ChangeControlDTO | null>(
    null,
  );

  const [isCreatingDoc, setIsCreatingDoc] = useState(false);

  const saveDocument: SubmitHandler<PropertiesForm> = async (data) => {
    if (!data.title || !data.format || !data.type) {
      return;
    }
    setIsCreatingDoc(true);

    const documentProperties: DocumentPropertiesDTO = {
      title: data.title,
      ...data.type,
      document_format: data.format,
      company_id: currentUser.companyId,
    };

    let newDocument: any;

    try {
      newDocument = await documentApi.createDraft(
        currentUser.companyId,
        documentProperties,
      );
    } catch (e: unknown) {
      setIsCreatingDoc(false);
      showToast({
        id: 'create-document-error',
        status: 'error',
        title: 'Cannot create new document',
        description: 'Failed to create new document. Please try again',
        replace: true,
      });

      return;
    }

    await changeControlApi.createChangeControl(
      newDocument,
      changeControlTemplateId as number,
      changeControl as ChangeControlDTO,
    );

    if (qualioFrontendRefresh) {
      navigate(`/workspace/e/${newDocument.id}?intent=edit`);
    } else {
      window.location.assign(`/workspace/e/${newDocument.id}`);
    }
  };

  const fetchTemplates = async () => {
    const templates = await templateApi.getTemplatesForCompany(
      currentUser.companyId,
    );
    const templateSelectOptions = templates
      .map((template: Template) => ({
        label: template.name + ' (' + template.prefix + ')',
        value: template.id.toString(),
        properties: {
          effective_on_approval: template.document_effective_on_approval,
          training_required: template.training_required,
          review_period: template.document_review_period,
          training_available: template.training_available,
          file_download_user_groups: template.file_download_user_groups
            ? template.file_download_user_groups
            : [],
          template_id: template.id,
          id: template.id,
        },
      }))
      .sort(sortByLabel);
    setTemplateOptions(templateSelectOptions as any);
  };

  const fetchChangeControlTemplates = async () => {
    const templates = await changeControlApi.fetchChangeControlTemplates();
    if (templates && templates.length > 0) {
      const changeControlTemplate = templates[0];

      setChangeControl({
        sections: changeControlTemplate.sections,
      });
      setChangeControlTemplateId(changeControlTemplate.id);
    }
  };

  useQuery({
    queryFn: fetchTemplates,
    queryKey: ['documentTemplates'],
    refetchOnWindowFocus: false,
  });

  useQuery({
    queryFn: fetchChangeControlTemplates,
    queryKey: ['changeControl'],
    refetchOnWindowFocus: false,
    onError: () => {
      showToast({
        id: 'fetch-cc-template-error',
        status: 'error',
        title: 'Error',
        description:
          'Failed to get information required for document creation. Please try again.',
        replace: true,
      });
    },
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    trigger,
    reset,
  } = useForm<PropertiesForm>({
    defaultValues: {
      title: '',
      type: null,
      format: DocumentFormat.QualioNativeDocument,
    },
  });

  const handleCloseModal = () => {
    reset();
    setIsOpen(false);
  };

  const handleCreateDocument = handleSubmit(saveDocument);

  const { onChange, ...titleProps } = register('title', {
    required: 'Title is required',
  });
  const handleTitleChange = (event: SyntheticEvent) => {
    void onChange(event);
    void trigger('title');
  };

  if (!currentUser.permissions.can_author_doc) {
    return null;
  }

  return (
    <>
      <QMenuButton buttonLabel="New" data-cy="createNewDocMenu">
        <QMenuItem
          key="create-new-doc"
          data-testid="createNewDocButton"
          data-cy="createNewDocButton"
          onClick={() => setIsOpen(true)}
          fontSize={'sm'}
        >
          Document
        </QMenuItem>
        {bulkFileDocumentUpload && (
          <QMenuItem
            key="bulk-import-docs"
            data-testid="bulkFileUploadButton"
            data-cy="bulkFileUploadButton"
            onClick={() => window.location.assign('/upload')}
            fontSize={'sm'}
          >
            Import
          </QMenuItem>
        )}
      </QMenuButton>
      <QModal size={'xl'} isOpen={isOpen} onClose={handleCloseModal}>
        <QModalHeader>
          <QText>Create Document</QText>
        </QModalHeader>
        <QModalBody>
          <QStack spacing={3}>
            <QFormControl
              label="Title"
              isInvalid={Boolean(errors.title)}
              error={errors.title?.message as any}
            >
              <QInput
                placeholder={'Document title...'}
                data-cy="document-title"
                data-testid="document-title"
                onChange={handleTitleChange}
                {...titleProps}
              />
            </QFormControl>
            <QFormControl
              label="Type"
              isInvalid={Boolean(errors.type)}
              error={errors.type?.message as any}
            >
              <QStack spacing={3}>
                <Controller
                  name="type"
                  control={control}
                  rules={{
                    required: 'Document type is required',
                  }}
                  render={({ field: { onChange, value } }) => {
                    // type of selected is TemplateSelectOption
                    // but the onChange of QSelect doesn't
                    // accept an object as a type
                    const handleChange = (selected: any) => {
                      onChange(selected.properties);
                      void trigger('type');
                    };
                    return (
                      <QSelect
                        aria-label="documentType"
                        data-testid="template-selector"
                        data-cy="template-selector"
                        size="sm"
                        options={templateOptions}
                        onChange={handleChange}
                        value={value?.id?.toString()}
                        isLoading={!templateOptions.length}
                        isInvalid={Boolean(errors.type)}
                      >
                        <QSelectPlaceholder>
                          <QText>Select a document type...</QText>
                        </QSelectPlaceholder>
                      </QSelect>
                    );
                  }}
                />
              </QStack>
            </QFormControl>
            <Controller
              name="format"
              control={control}
              render={({ field: { onChange, value } }) => (
                <QRadioGroup onChange={onChange} value={value}>
                  <QStack spacing={6}>
                    <QStack spacing={1}>
                      <QRadio value={DocumentFormat.QualioNativeDocument}>
                        <QText>New document</QText>
                      </QRadio>
                      <QText color={'gray.500'} pl={6} fontSize={'sm'}>
                        Create new controlled content using Qualio's editor
                      </QText>
                    </QStack>
                    <QStack spacing={1}>
                      <QRadio value={DocumentFormat.DocumentUpload}>
                        <QText>
                          Upload (.docx, .pdf, .png, .jpg, .pptx, .odf)
                        </QText>
                      </QRadio>
                      <QText pl={6} color={'gray.500'} fontSize={'sm'}>
                        Control an existing Microsoft Word, PowerPoint, PDF, or
                        image file.
                      </QText>
                    </QStack>
                  </QStack>
                </QRadioGroup>
              )}
            />
          </QStack>
        </QModalBody>
        <QModalActions>
          <QButton
            data-testid={'cancel-create-button'}
            variant="outline"
            isDisabled={isCreatingDoc}
            onClick={handleCloseModal}
          >
            Cancel
          </QButton>
          <QButton
            data-testid={'confirm-create-button'}
            onClick={handleCreateDocument}
            isDisabled={isCreatingDoc}
            isLoading={isCreatingDoc || !changeControl}
          >
            Create
          </QButton>
        </QModalActions>
      </QModal>
    </>
  );
};
