import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';

import {
  DocumentStatus,
  DocumentTrainee,
  GroupUser,
  QualioDocument,
} from '../../api/model/document';
import { PeriodicReview } from '../../api/model/periodicReview';
import { MedtechUserV2 } from '../../api/user';

export const currentUserHasAccessToDocument = (
  user: CurrentUser,
  qualioDocument: QualioDocument,
): boolean => {
  /*
   *  Status -> who gets to see it:
   *  1. draft document -> owner, quality
   *  2. for review -> owner, quality, reviewer
   *  3. (for approval | approval declined) -> owner, quality, approver
   *  4. approved -> owner, quality, normal, trainee
   *  5. effective -> everyone
   *  6. retired -> owner, quality
   *  7. superseded -> owner, quality, normal, trainee
   *  8. deleted -> owner, quality
   */

  //this does not yet exist in the component library as it is not being passed from MTFE
  if ((user.company as any).ghost) {
    return true;
  }

  // owner and quality users can always access
  if (
    qualioDocument.owner.id === user.userId ||
    isQualityUserInAccount(user) ||
    isEditor(user, qualioDocument)
  ) {
    return true;
  }

  switch (qualioDocument.status_id) {
    case DocumentStatus.For_Review:
      return isReviewer(user, qualioDocument);
    case DocumentStatus.Approval_Declined:
    case DocumentStatus.For_Approval:
      return isApprover(user, qualioDocument);
    case DocumentStatus.Superseded:
    case DocumentStatus.Approved:
      return isNormalUserInAccount(user) || isTrainee(user, qualioDocument);
    case DocumentStatus.Effective:
      return true;
    default:
      return false;
  }
};

export const userIsInGroup = (
  currentUser: CurrentUser,
  group: GroupUser[],
): boolean => {
  return Boolean(group.find((user) => user.user_id === currentUser.userId));
};

export const getUserInGroup = (
  currentUser: CurrentUser,
  group: GroupUser[],
): GroupUser | undefined => {
  return group.find((user) => user.user_id === currentUser.userId);
};

export const isReviewer = (
  currentUser: CurrentUser,
  document: QualioDocument,
): boolean => {
  const { audit_groups } = document;

  if (audit_groups) {
    const { review_group } = audit_groups;

    return userIsInGroup(currentUser, review_group ?? []);
  }

  return false;
};

export const hasReviewed = (
  currentUser: CurrentUser,
  document: QualioDocument,
): boolean => {
  const { audit_groups } = document;

  if (audit_groups) {
    const { review_group } = audit_groups;
    const reviewer = getUserInGroup(currentUser, review_group ?? []);

    return Boolean(reviewer?.action_done);
  }

  return false;
};

export const isApprover = (
  currentUser: CurrentUser,
  document: QualioDocument,
): boolean => {
  const { audit_groups } = document;

  if (audit_groups) {
    const { approver_group } = audit_groups;

    return userIsInGroup(currentUser, approver_group || []);
  }

  return false;
};

export const isTrainee = (
  currentUser: CurrentUser,
  document: QualioDocument,
): boolean => {
  const { trainees } = document;

  if (trainees) {
    return (
      trainees.findIndex(
        (trainee: DocumentTrainee) => trainee.id === currentUser.userId,
      ) !== -1
    );
  }

  return false;
};

export const isApproverWithPendingApproval = (
  currentUser: CurrentUser,
  qualioDocument: QualioDocument,
) => {
  return !!qualioDocument.audit_groups?.approver_group?.find(
    ({ user_id, action_done }) =>
      user_id === currentUser.userId && !action_done,
  );
};

export const isOwner = (
  currentUser: CurrentUser,
  document: QualioDocument,
): boolean => {
  return currentUser.userId === document.owner.id;
};

export const isEditor = (
  currentUser: CurrentUser,
  document: QualioDocument,
): boolean => {
  const { audit_groups: { editor_group = [] } = {} } = document;

  return editor_group.some(({ id }) => id === currentUser.userId);
};

export const hasPermissions = (
  currentUser: CurrentUser,
  requiredPermissions: string[],
) => {
  const { permissions } = currentUser;

  return requiredPermissions.every((perm) => {
    return Boolean(permissions[perm]);
  });
};

export const isQualityUserInAccount = (currentUser: CurrentUser) => {
  return currentUser.userGroups.includes('quality');
};
export const isNormalUserInAccount = (currentUser: CurrentUser) => {
  return currentUser.userGroups.includes('normal');
};

export const isBasicUserInAccount = (currentUser: CurrentUser) => {
  return currentUser.userGroups.includes('read_only');
};

export const isQualityUsergroup = (user: GroupUser | MedtechUserV2) =>
  user.usergroups[0] === 'quality';

export const isNormalUsergroup = (user: GroupUser | MedtechUserV2) =>
  user.usergroups[0] === 'normal';

export const isBasicUsergroup = (user: GroupUser | MedtechUserV2) =>
  user.usergroups[0] === 'read_only';

export const hasApprovedPeriodicReview = (
  currentUser: CurrentUser,
  periodicReview: PeriodicReview,
) => {
  const userHasApproved = periodicReview.discussion.some((item) => {
    return item.user.id === currentUser.userId && item.approved !== undefined;
  });

  return userHasApproved;
};
