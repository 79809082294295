import React, { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import {
  DataProvider,
  Filtering,
  Pagination,
  QBodyLayout,
  QBox,
  QHeading,
  QStack,
  QTab,
  QTabList,
  QTabPanel,
  QTabPanels,
  QTabs,
  QText,
  Sorting,
  useCurrentUser,
  useToastProvider,
} from '@qualio/ui-components';
import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';

import { backendTaskApi } from '../../../api/backendTask';
import { documentApi } from '../../../api/document';
import { DocumentStatus, QualioDocument } from '../../../api/model/document';
import { Tag } from '../../../api/model/tag';
import { TagApi } from '../../../api/tag';

import { DocumentContainer } from '../DocumentContainer/index';
import { SearchFilter } from '../DocumentLibraryV2/SearchFilter';
import { TagsFilter } from '../DocumentLibraryV2/TagsFilter';
import { BulkActionsWrapper } from './BulkActionsWrapper';
import { ExportButtonWrapper } from './ExportButtonWrapper';
import { ListSelectionWrapper } from './ListSelectionWrapper';
import { NewDocumentButton } from './NewDocumentButton';
import { StatusFilter } from './StatusFilter';
import {
  ALL_OPEN_STATUSES,
  FOR_TRAINING_STATUS,
  WorkspaceTabProps,
  WorkspaceTabs,
} from './types';
import { buildSelectedStatus } from './utils';
import { WorkspaceList } from './WorkspaceList';

export const documentAllOpenStatuses = [
  DocumentStatus.Draft,
  DocumentStatus.For_Review,
  DocumentStatus.For_Approval,
  DocumentStatus.Approved,
  DocumentStatus.Approval_Declined,
  DocumentStatus.Effective,
];

export const actionableDocumentAllOpenStatusFilters = [
  'include_pending_review',
  'include_pending_approve',
  'include_pending_make_effective',
];

const FilterDefinitions = {
  filteringTag: {
    label: 'Tags not selected',
    schema: Filtering.schemas.StringSchema(),
  },
  subfilter: {
    label: 'Status not selected',
    schema: Filtering.schemas.StringSchema(),
  },
  q: {
    label: 'Search',
    schema: Filtering.schemas.StringSchema(),
  },
} as const;

const getStatusFiltersDocumentsMap = (
  tab: WorkspaceTabs,
  permissions: CurrentUser['permissions'],
) => {
  return {
    // statuses other than approved and effective are only avaiable options if the user can view all docs or if the currently selected tab is "your documents"
    ...((tab === WorkspaceTabs.YOUR_DOCUMENTS ||
      permissions.can_view_all_docs) && {
      [ALL_OPEN_STATUSES]: {
        label: 'All open statuses',
        statusFilters: documentAllOpenStatuses,
      },
      [DocumentStatus.Draft]: {
        label: 'Draft',
        statusFilters: [DocumentStatus.Draft],
      },
      [DocumentStatus.For_Review]: {
        label: 'For review',
        statusFilters: [DocumentStatus.For_Review],
      },
      [DocumentStatus.For_Approval]: {
        label: 'For approval',
        statusFilters: [DocumentStatus.For_Approval],
      },
    }),
    [DocumentStatus.Approved]: {
      label: 'Approved',
      statusFilters: [DocumentStatus.Approved],
    },
    ...((tab === WorkspaceTabs.YOUR_DOCUMENTS ||
      permissions.can_view_all_docs) && {
      [DocumentStatus.Approval_Declined]: {
        label: 'Approval declined',
        statusFilters: [DocumentStatus.Approval_Declined],
      },
    }),
    [DocumentStatus.Effective]: {
      label: 'Effective',
      statusFilters: [DocumentStatus.Effective],
    },
    ...((tab === WorkspaceTabs.YOUR_DOCUMENTS ||
      permissions.can_view_all_docs) && {
      [DocumentStatus.For_Periodic_Review]: {
        label: 'For periodic review',
        statusFilters: [DocumentStatus.For_Periodic_Review],
      },
      [DocumentStatus.Retired]: {
        label: 'Retired',
        statusFilters: [DocumentStatus.Retired],
      },
      [DocumentStatus.Deleted]: {
        label: 'Deleted drafts',
        statusFilters: [DocumentStatus.Deleted],
      },
    }),
  };
};

const statusFiltersActionsMap = {
  [ALL_OPEN_STATUSES]: {
    label: 'All open statuses',
    statusFilters: actionableDocumentAllOpenStatusFilters,
  },
  [FOR_TRAINING_STATUS]: {
    label: 'For training',
    statusFilters: null,
  },
  [DocumentStatus.For_Review]: {
    label: 'For review',
    statusFilters: ['include_pending_review'],
  },
  [DocumentStatus.For_Approval]: {
    label: 'For approval',
    statusFilters: ['include_pending_approve'],
  },
  [DocumentStatus.Approved]: {
    label: 'Approved',
    statusFilters: ['include_pending_make_effective'],
  },
  [DocumentStatus.For_Periodic_Review]: {
    label: 'For periodic review',
    statusFilters: ['include_pending_periodic_review'],
  },
};

const getParams = (
  pageParam: DataProvider.PageParams,
  currentUser: CurrentUser,
  requestedTab: WorkspaceTabs | null,
  currentTabConfig: WorkspaceTabProps,
) => {
  const params = pageParam?.toUrlSearchParams();
  const tagsParam = params.get('filteringTag');
  const tagsIds = tagsParam ? tagsParam.split(',') : [];
  const orderBy = params.get('order_by');
  const statusFilter = params.get('subfilter');
  const selectedStatuses = buildSelectedStatus(
    statusFilter,
    currentUser,
    requestedTab,
    currentTabConfig,
  );
  const searchQuery = params.get('query');

  return {
    tagsIds,
    orderBy,
    selectedStatuses,
    searchQuery,
  };
};

export const DocumentWorkspaceV2: React.FC = () => {
  const currentUser = useCurrentUser();
  const { showToast } = useToastProvider();
  const WorkspaceTabsConfig: { [key in WorkspaceTabs]: WorkspaceTabProps } =
    useMemo(
      () => ({
        [WorkspaceTabs.YOUR_DOCUMENTS]: {
          id: WorkspaceTabs.YOUR_DOCUMENTS,
          defaultFetchMethod: documentApi.fetchUserDocs,
          statusFiltersMap: getStatusFiltersDocumentsMap(
            WorkspaceTabs.YOUR_DOCUMENTS,
            currentUser.permissions,
          ),
          defaultExportTaskMethod: backendTaskApi.startUserDocumentsExportTask,
        },
        [WorkspaceTabs.YOUR_ACTIONS]: {
          id: WorkspaceTabs.YOUR_ACTIONS,
          defaultFetchMethod: documentApi.fetchUserActionableDocs,
          statusFiltersMap: statusFiltersActionsMap,
          defaultExportTaskMethod:
            backendTaskApi.startUserActionableDocumentsExportTask,
        },
        [WorkspaceTabs.ALL_DOCUMENTS]: {
          id: WorkspaceTabs.ALL_DOCUMENTS,
          defaultFetchMethod: documentApi.fetchAll,
          statusFiltersMap: getStatusFiltersDocumentsMap(
            WorkspaceTabs.ALL_DOCUMENTS,
            currentUser.permissions,
          ),
          defaultExportTaskMethod: backendTaskApi.startAllDocumentsExportTask,
        },
      }),
      [currentUser.permissions],
    );
  const [searchParams, setSearchParams] = useSearchParams();
  const requestedStatus = searchParams.get('subfilter');
  const requestedTab = searchParams.get('filter') as WorkspaceTabs;

  const [currentTabConfig, setCurrentTabConfig] = useState<WorkspaceTabProps>(
    requestedTab
      ? WorkspaceTabsConfig[requestedTab]
      : WorkspaceTabsConfig[WorkspaceTabs.YOUR_DOCUMENTS],
  );

  const tabConfigKeysInDisplayedOrder = [
    WorkspaceTabs.YOUR_DOCUMENTS,
    WorkspaceTabs.YOUR_ACTIONS,
    WorkspaceTabs.ALL_DOCUMENTS,
  ];
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(
    tabConfigKeysInDisplayedOrder.indexOf(currentTabConfig.id),
  );

  const [selectedDocumentsMap, setSelectedDocumentsMap] = useState<
    Map<QualioDocument['id'], QualioDocument>
  >(new Map());

  const [isTableReadyForExport, setIsTableReadyForExport] = useState(false);

  const resetAllSearchParams = () => {
    searchParams.delete('filteringTag');
    searchParams.delete('query');
    searchParams.delete('page');
    searchParams.delete('count');
    searchParams.delete('subfilter');
    searchParams.delete('order_by');
  };

  const loadTags = async (): Promise<Tag[]> => TagApi.fetchTags(currentUser);

  const { data: fetchedTags = [], isLoading: isLoadingTags } = useQuery({
    queryFn: loadTags,
    queryKey: ['tags', currentUser.companyId],
    refetchOnWindowFocus: false,
  });

  const handleTabClick = (tabIndex: number) => {
    setCurrentTabIndex(tabIndex);
    resetAllSearchParams();
    const tabConfig =
      WorkspaceTabsConfig[tabConfigKeysInDisplayedOrder[tabIndex]];
    setCurrentTabConfig(tabConfig);
    searchParams.set('filter', tabConfig.id);
    setSearchParams(searchParams);
  };

  const deletedDraftStatusSelected = useMemo(() => {
    return requestedStatus === DocumentStatus.Deleted;
  }, [requestedStatus]);

  const {
    Provider: PaginationProvider,
    offset,
    limit,
  } = Pagination.useLimitOffset();

  const handleDocumentFetchError = useCallback(
    (error?: unknown) => {
      console.error(error);
      showToast({
        id: 'fetch-documents-toast-error',
        status: 'error',
        title: 'Error',
        description: 'Failed to load documents. Please try again.',
        replace: true,
      });
    },
    [showToast],
  );

  const fetchDocuments = async ({
    pageParam,
  }: {
    pageParam: DataProvider.PageParams;
  }) => {
    try {
      switch (currentTabConfig.id) {
        case WorkspaceTabs.YOUR_DOCUMENTS:
          return await fetchUserDocs({ pageParam });
        case WorkspaceTabs.YOUR_ACTIONS:
          return await fetchActions({ pageParam });
        case WorkspaceTabs.ALL_DOCUMENTS:
          return await fetchAllDocuments({ pageParam });
      }
    } catch (error) {
      handleDocumentFetchError(error);
      return Promise.resolve({
        itemCount: 0,
        data: [],
      });
    }
  };

  const fetchUserDocs = async ({
    pageParam,
  }: {
    pageParam: DataProvider.PageParams;
  }): Promise<DataProvider.TDataWithCount<QualioDocument>> => {
    const { selectedStatuses, searchQuery, tagsIds, orderBy } = getParams(
      pageParam,
      currentUser,
      requestedTab,
      currentTabConfig,
    );
    const results = await documentApi.fetchUserDocs(
      currentUser,
      selectedStatuses,
      searchQuery,
      tagsIds,
      offset,
      limit,
      orderBy,
    );

    return {
      itemCount: results.total,
      data: results.documents,
    };
  };

  const fetchAllDocuments = async ({
    pageParam,
  }: {
    pageParam: DataProvider.PageParams;
  }): Promise<DataProvider.TDataWithCount<QualioDocument>> => {
    const { selectedStatuses, searchQuery, tagsIds, orderBy } = getParams(
      pageParam,
      currentUser,
      requestedTab,
      currentTabConfig,
    );
    const results = await documentApi.fetchAll(
      currentUser,
      selectedStatuses,
      searchQuery,
      tagsIds,
      offset,
      limit,
      orderBy,
    );

    return {
      itemCount: results.total,
      data: results.documents,
    };
  };

  const fetchActions = async ({
    pageParam,
  }: {
    pageParam: DataProvider.PageParams;
  }): Promise<DataProvider.TDataWithCount<QualioDocument>> => {
    const { selectedStatuses, searchQuery, tagsIds, orderBy } = getParams(
      pageParam,
      currentUser,
      requestedTab,
      currentTabConfig,
    );

    const fetchActionsMethod =
      requestedStatus === FOR_TRAINING_STATUS
        ? documentApi.fetchUserTrainingDocs
        : documentApi.fetchUserActionableDocs;

    const results = await fetchActionsMethod(
      currentUser,
      selectedStatuses,
      searchQuery,
      tagsIds,
      offset,
      limit,
      orderBy,
    );

    return {
      itemCount: results.total,
      data: results.documents,
    };
  };

  return (
    <DocumentContainer>
      <Filtering.FilterProvider
        searchTermKey="query"
        definitions={FilterDefinitions}
      >
        <QBox width={'100%'} overflowX={'hidden'}>
          <QBodyLayout.Default>
            <QStack isInline justify="space-between">
              <QHeading size="lg" mb={6} data-cy="workspace-heading">
                Workspace
              </QHeading>
              <QBox>
                <QStack isInline zIndex="3" position="relative">
                  <ExportButtonWrapper
                    currentTab={requestedTab}
                    currentTabConfig={currentTabConfig}
                    isReadyForExport={isTableReadyForExport}
                  />
                  <NewDocumentButton currentUser={currentUser} />
                </QStack>
              </QBox>
            </QStack>
            <QTabs
              variant="enclosed"
              onChange={handleTabClick}
              index={currentTabIndex}
            >
              <QTabList>
                <QTab data-cy="your-docs-tab">Your documents</QTab>
                <QTab data-cy="your-actions-tab">Your actions</QTab>
                <QTab data-cy="all-docs-tab">All documents</QTab>
              </QTabList>
              <QStack isInline pt="4" pb="2" pl="0.5">
                <PaginationProvider>
                  <StatusFilter
                    statusFiltersMap={currentTabConfig.statusFiltersMap}
                    currentTab={requestedTab as WorkspaceTabs}
                  />
                  <TagsFilter
                    isLoadingTags={isLoadingTags}
                    fetchedTags={fetchedTags}
                  />
                  <SearchFilter id="workspace" />
                </PaginationProvider>
              </QStack>
              {deletedDraftStatusSelected && (
                <QText fontSize="sm">
                  Deleted items will remain in this view for 90 days
                </QText>
              )}
              {!deletedDraftStatusSelected && (
                <BulkActionsWrapper
                  currentUser={currentUser}
                  selectedDocumentsMap={selectedDocumentsMap}
                  currentTab={currentTabConfig.id}
                  setSelectedDocumentsMap={setSelectedDocumentsMap}
                  tags={fetchedTags}
                />
              )}
              <QTabPanels>
                <QTabPanel p="0">
                  {currentTabConfig.id === WorkspaceTabs.YOUR_DOCUMENTS && (
                    <PaginationProvider>
                      <Sorting.DefaultSortingProvider sortByKey="order_by">
                        <DataProvider.Remote
                          queryFn={fetchDocuments}
                          queryKey={['getDocuments', currentUser.userId]}
                          queryOptions={{ enabled: !isLoadingTags }}
                          queryParamKeys={{
                            searchTerm: 'query',
                            sortBy: 'order_by',
                            pageSize: 'limit',
                            pageIndex: 'pageIndex',
                          }}
                        >
                          <ListSelectionWrapper
                            isReadOnly={deletedDraftStatusSelected}
                          >
                            <WorkspaceList
                              fetchedTags={fetchedTags}
                              selectedDocumentsMap={selectedDocumentsMap}
                              setSelectedDocumentsMap={setSelectedDocumentsMap}
                              setIsTableReadyForExport={
                                setIsTableReadyForExport
                              }
                            />
                          </ListSelectionWrapper>
                        </DataProvider.Remote>
                      </Sorting.DefaultSortingProvider>
                    </PaginationProvider>
                  )}
                </QTabPanel>
                <QTabPanel p="0">
                  {currentTabConfig.id === WorkspaceTabs.YOUR_ACTIONS && (
                    <PaginationProvider>
                      <Sorting.DefaultSortingProvider sortByKey="order_by">
                        <DataProvider.Remote
                          queryFn={fetchDocuments}
                          queryKey={['getYourActions', currentUser.userId]}
                          queryParamKeys={{
                            searchTerm: 'query',
                            sortBy: 'order_by',
                            pageSize: 'limit',
                            pageIndex: 'pageIndex',
                          }}
                        >
                          <ListSelectionWrapper
                            isReadOnly={deletedDraftStatusSelected}
                          >
                            <WorkspaceList
                              fetchedTags={fetchedTags}
                              selectedDocumentsMap={selectedDocumentsMap}
                              setSelectedDocumentsMap={setSelectedDocumentsMap}
                              setIsTableReadyForExport={
                                setIsTableReadyForExport
                              }
                            />
                          </ListSelectionWrapper>
                        </DataProvider.Remote>
                      </Sorting.DefaultSortingProvider>
                    </PaginationProvider>
                  )}
                </QTabPanel>
                <QTabPanel p="0">
                  {currentTabConfig.id === WorkspaceTabs.ALL_DOCUMENTS && (
                    <PaginationProvider>
                      <Sorting.DefaultSortingProvider sortByKey="order_by">
                        <DataProvider.Remote
                          queryFn={fetchDocuments}
                          queryKey={['getAllDocuments', currentUser.userId]}
                          queryParamKeys={{
                            searchTerm: 'query',
                            sortBy: 'order_by',
                            pageSize: 'limit',
                            pageIndex: 'pageIndex',
                          }}
                        >
                          <ListSelectionWrapper
                            isReadOnly={deletedDraftStatusSelected}
                          >
                            <WorkspaceList
                              fetchedTags={fetchedTags}
                              selectedDocumentsMap={selectedDocumentsMap}
                              setSelectedDocumentsMap={setSelectedDocumentsMap}
                              setIsTableReadyForExport={
                                setIsTableReadyForExport
                              }
                            />
                          </ListSelectionWrapper>
                        </DataProvider.Remote>
                      </Sorting.DefaultSortingProvider>
                    </PaginationProvider>
                  )}
                </QTabPanel>
              </QTabPanels>
            </QTabs>
          </QBodyLayout.Default>
        </QBox>
      </Filtering.FilterProvider>
    </DocumentContainer>
  );
};
