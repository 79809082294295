import {
  ActivityStatus,
  CurrentUserContextType,
  QActivitySequence,
  QButton,
  QStack,
  QText,
} from '@qualio/ui-components';
import { Parser } from 'html-to-react';
import { ReactElement, useMemo, useState } from 'react';

interface CommentThreadProps {
  thread: CommentsRepository.CommentThreadFetched;
  currentUser: CurrentUserContextType;
}

interface ThreadCommentsProps {
  contentCommentedOnTargets:
    | CommentsRepository.CommentThreadFetched['content_commented_on'];
  commentContent: ReactElement;
  commentIndex: number;
}

interface ContentCommentedOnProps {
  contentCommentedOnTargets:
    | CommentsRepository.CommentThreadFetched['content_commented_on'];
}

const LETTER_LIMIT_CONTENT_COMMENTED_ON = 120;

export const parseCommentInThread = (
  thread: CommentsRepository.CommentThreadFetched,
  currentUser: CurrentUserContextType,
) => {
  const htmlToReactParser = new Parser();
  return thread.comments.map((comment, index: number) => ({
    status: 'commented' as ActivityStatus,
    fullName: comment.attributes.authorFullName ?? comment.user.id,
    actionTimestamp: currentUser.formatDate(
      comment.updated_at || comment.created_at,
      true,
    ),
    children: (
      <ThreadComments
        contentCommentedOnTargets={thread.content_commented_on}
        commentContent={htmlToReactParser.parse(comment.content)}
        commentIndex={index}
      ></ThreadComments>
    ),
  }));
};

export const ContentCommentedOn = ({
  contentCommentedOnTargets,
}: ContentCommentedOnProps) => {
  const [showAllContentCommentOn, setShowAllContentCommentOn] = useState(false);
  const toggleShowAllContentCommentedOn = () => {
    setShowAllContentCommentOn(!showAllContentCommentOn);
  };

  const targetIsImage = (target: CommentsRepository.Target) =>
    target.name === 'imageBlock' || target.name === 'imageInline';

  const contentCommentedOnText = useMemo(() => {
    if (!contentCommentedOnTargets) {
      return 'Not available';
    }

    return contentCommentedOnTargets
      .map((target) => {
        if (targetIsImage(target)) {
          return '[Image element]';
        }
        if (target.name === 'table') {
          return '[Table element]';
        } else {
          return target.data;
        }
      })
      .join(' ');
  }, [contentCommentedOnTargets]);

  if (!contentCommentedOnTargets) {
    return (
      <QText>
        <b>Commented on: </b> Not available
      </QText>
    );
  }

  if (
    contentCommentedOnTargets.length === 1 &&
    targetIsImage(contentCommentedOnTargets[0])
  ) {
    return (
      <QText>
        <b>Commented on: </b> Image element
      </QText>
    );
  }

  if (
    contentCommentedOnTargets.length === 1 &&
    contentCommentedOnTargets[0].name === 'table'
  ) {
    return (
      <QText>
        <b>Commented on: </b> Table element
      </QText>
    );
  }

  const getDisplayText = (contentCommentedOnText: string) => {
    const displayLongText = `"${contentCommentedOnText}" `;
    const displayShortText = `"${
      contentCommentedOnText.substring(0, LETTER_LIMIT_CONTENT_COMMENTED_ON) +
      (contentCommentedOnText.length > LETTER_LIMIT_CONTENT_COMMENTED_ON
        ? '...'
        : '"')
    }`;

    return showAllContentCommentOn ? displayLongText : displayShortText;
  };

  return (
    <QText>
      <b>Commented on: </b>
      {getDisplayText(contentCommentedOnText)}
      {contentCommentedOnText.length > LETTER_LIMIT_CONTENT_COMMENTED_ON && (
        <QButton onClick={toggleShowAllContentCommentedOn} variant="link">
          {showAllContentCommentOn ? 'Show less' : 'Show more'}
        </QButton>
      )}
    </QText>
  );
};

export const ThreadComments = ({
  contentCommentedOnTargets,
  commentContent,
  commentIndex,
}: ThreadCommentsProps) => {
  return (
    <QStack>
      {commentIndex === 0 && (
        <ContentCommentedOn
          contentCommentedOnTargets={contentCommentedOnTargets}
        ></ContentCommentedOn>
      )}
      <QText data-testid="comment-content">{commentContent}</QText>
    </QStack>
  );
};

export const OpenCommentThread = ({
  thread,
  currentUser,
}: CommentThreadProps) => {
  const activities = parseCommentInThread(thread, currentUser);
  const { status, comments, content_commented_on } = thread;
  const isOpenSuggestion =
    status === 'Open' &&
    !content_commented_on &&
    comments.some((comment) => comment?.attributes?.suggestionId);

  // filter open threads on suggestion, as we only show them once the suggestion has been accepted or declined
  if (isOpenSuggestion) {
    return null;
  }

  return <QActivitySequence activities={activities} key={thread.id} />;
};

export const ResolvedCommentThread = ({
  thread,
  currentUser,
}: CommentThreadProps) => {
  const activities = [
    ...parseCommentInThread(thread, currentUser),
    {
      status: 'markedResolved' as ActivityStatus,
      fullName: thread.deleted_by_user ?? 'Unknown user',
      actionTimestamp: thread.deleted_at
        ? currentUser.formatDate(thread.deleted_at, true)
        : 'Unkown time',
    },
  ];
  return <QActivitySequence activities={activities} key={thread.id} />;
};
