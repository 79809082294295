import { createContext, SetStateAction } from 'react';
import { QualioDocument } from '../../../api/model/document';
import { ChangeControl } from '../../../types/DocumentCreate';
import { EditorMode } from '../../../util/EditModeUtils';

export type DocumentOverviewContextType = {
  qualioDocument: QualioDocument;
  changeControl: ChangeControl;
  currentEditorMode: EditorMode;
  collaborationToken: string;
  commentsPermission: number;
  refetchDocument: (fetchLatestVersion?: boolean) => void;
  updateEditorMode: (mode: EditorMode) => void;
};

export const DocumentOverviewContext =
  createContext<DocumentOverviewContextType>({} as any);

export enum EditorStatus {
  CONNECTING,
  STABLE,
  SAVING,
  SAVED,
  ERROR,
  PENDING_AUTOSAVE,
}

export type EditorStatusContextType = {
  docEditorStatus: EditorStatus;
  ccEditorStatus: EditorStatus;
  trainingAssessmentStatus: EditorStatus;
  setCCEditorStatus: React.Dispatch<SetStateAction<EditorStatus>>;
  setDocEditorStatus: React.Dispatch<SetStateAction<EditorStatus>>;
  setTrainingAssessmentStatus: React.Dispatch<SetStateAction<EditorStatus>>;
};

export const EditorStatusContext = createContext<EditorStatusContextType>(
  {} as any,
);
