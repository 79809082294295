import { useCallback, useContext, useMemo, useState } from 'react';

import {
  QButton,
  QLookup,
  QModalHeader,
  useCurrentUser,
  useToastProvider,
} from '@qualio/ui-components';

import { documentApi } from '../../../../../../api/document';
import {
  DocumentCategory,
  DocumentStatus,
  GroupUser,
  QualioDocumentAuditGroups,
  QualioDocumentAuditGroupsPatch,
  QualioDocumentPatch,
} from '../../../../../../api/model/document';
import { Group } from '../../../../../../api/model/group';
import { Tag } from '../../../../../../api/model/tag';
import { MedtechUserV2 } from '../../../../../../api/user';

import {
  canUserAccessAtLeastOnePrivateTag,
  filterUserOptionLookup,
  getDocumentTagIdsToGroupIdsMap,
  getUserLookupView,
} from '../../utils';

import { DocumentOverviewContext } from '../../../Context';
import { shouldRenderManageApproversButton } from '../../../RenderLogic';

import { QLookupModalComposedHeader } from '../../../../QLookupModalComposedHeader';
import {
  ManageApproversConfirmationModal,
  ManageApproversConfirmationModalProps,
} from './ManageApproversConfirmationModal';

const mapToId = ({ id }: { id: number }) => id;

export type ManageApproversButtonProps = {
  modalTitle: string;
  modalSubtitle: string;
  tags: Tag[];
  groups: Group[];
  users: MedtechUserV2[];
  currentApprovers: QualioDocumentAuditGroups['approver_group'];
  approverType: 'quality' | 'other';
};

export const ManageApproversButton = ({
  approverType,
  modalTitle,
  modalSubtitle,
  tags,
  groups,
  users,
  currentApprovers = [],
}: ManageApproversButtonProps) => {
  const currentUser = useCurrentUser();
  const { showToast } = useToastProvider();
  const [isLookupModalOpen, setIsLookupModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [confirmationModalProps, setConfirmationModalProps] =
    useState<
      Pick<
        ManageApproversConfirmationModalProps,
        | 'addedApprovers'
        | 'removedApprovers'
        | 'selections'
        | 'onSubmit'
        | 'requiresComment'
      >
    >();
  const onClose = useCallback(() => {
    setIsLookupModalOpen(false);
    setIsConfirmationModalOpen(false);
    setConfirmationModalProps(undefined);
  }, []);

  const commonToastProps = {
    id: 'manage-approvers-property-toast',
    replace: true,
  };

  const onSuccess = () => {
    refetchDocument();
    showToast({
      ...commonToastProps,
      status: 'success',
      title: 'Approvers assigned!',
      description: 'Successfully changed the approvers of the document.',
    });
    onClose();
  };

  const onError = () => {
    showToast({
      ...commonToastProps,
      status: 'error',
      title: 'Error',
      description: 'Failed to apply changes to approvers. Please try again.',
    });
  };

  const { qualioDocument, refetchDocument } = useContext(
    DocumentOverviewContext,
  );

  const canManageApprovers = useMemo(
    () => shouldRenderManageApproversButton(qualioDocument, currentUser),
    [currentUser, qualioDocument],
  );

  const currentApproversMap = useMemo(
    () =>
      currentApprovers.reduce(
        (acc: Map<GroupUser['id'], GroupUser>, approver) => {
          return acc.set(approver.id, approver);
        },
        new Map(),
      ),
    [currentApprovers],
  );

  // Maintain any approvers that are not managed in this component
  const unmanagedApprovers = useMemo(
    () =>
      qualioDocument.audit_groups?.approver_group?.filter(
        (user) => !currentApproversMap.has(user.id),
      ) ?? [],
    [currentApproversMap, qualioDocument.audit_groups?.approver_group],
  );

  const documentTagIdsToGroupIdsMap = useMemo(
    () => getDocumentTagIdsToGroupIdsMap(qualioDocument, tags),
    [qualioDocument, tags],
  );

  const validApprovers = useMemo(
    () =>
      users.filter((user) => {
        const { full_name, permissions } = user;
        const isValidDocumentApprover =
          !!full_name &&
          (documentTagIdsToGroupIdsMap.size === 0 ||
            canUserAccessAtLeastOnePrivateTag(
              user,
              Array.from(documentTagIdsToGroupIdsMap.values()),
            ));
        const isIssueRelated =
          qualioDocument.category === DocumentCategory.ISSUE_RELATED;

        if (isIssueRelated) {
          return (
            isValidDocumentApprover &&
            permissions.includes('feature_enabled_quality_events')
          );
        }
        return isValidDocumentApprover;
      }),
    [documentTagIdsToGroupIdsMap, qualioDocument.category, users],
  );

  const userDataView = useMemo(() => getUserLookupView(groups), [groups]);

  const filterOptionCallback = useCallback(
    (user: MedtechUserV2, searchTerm: string | undefined) => {
      if (!searchTerm) {
        return true;
      }
      return filterUserOptionLookup(searchTerm, user, groups);
    },
    [groups],
  );

  const isApproverRowDisabled = useCallback(
    (approver: MedtechUserV2) => {
      const currentApprover = currentApproversMap.get(approver.id);
      const isApproverActionDone = currentApprover?.action_done;
      return !!isApproverActionDone;
    },
    [currentApproversMap],
  );

  const isCurrentApprover = useCallback(
    (user: Pick<MedtechUserV2, 'id'>) => currentApproversMap.has(user.id),
    [currentApproversMap],
  );

  // Check if the user is removing all pending approvers for document with approvals
  const isRemovingAllPendingApprovers = useMemo(() => {
    const removedApproversCount =
      confirmationModalProps?.removedApprovers?.length ?? 0;
    return (
      Boolean(qualioDocument.approvers_and_timestamps?.length) &&
      qualioDocument.status_id === DocumentStatus.For_Approval &&
      removedApproversCount > 0 &&
      removedApproversCount ===
        [...currentApprovers, ...unmanagedApprovers].filter(
          (approver) => approver.action_pending,
        ).length
    );
  }, [
    confirmationModalProps?.removedApprovers?.length,
    qualioDocument.approvers_and_timestamps?.length,
    qualioDocument.status_id,
    currentApprovers,
    unmanagedApprovers,
  ]);

  const buildAuditGroupPatch = useCallback(
    (approvers: number[]): QualioDocumentPatch => {
      const auditGroupPatch: QualioDocumentAuditGroupsPatch = {
        approver_group: [...approvers, ...unmanagedApprovers.map(mapToId)],
        review_group: (qualioDocument.audit_groups?.review_group ?? []).map(
          mapToId,
        ),
      };
      return {
        audit_groups: auditGroupPatch,
      };
    },
    [unmanagedApprovers, qualioDocument.audit_groups],
  );

  const getApproverChanges = useCallback(
    (selections: readonly MedtechUserV2[]) => {
      const selectedApproverIds = selections.map(mapToId);
      const addedApprovers = selections.filter(
        (user) => !isCurrentApprover(user),
      );
      const removedApprovers = currentApprovers.filter(
        (user) => !selectedApproverIds.includes(user.id),
      );
      return {
        addedApprovers,
        removedApprovers,
        selectedApproverIds,
      };
    },
    [currentApprovers, isCurrentApprover],
  );

  const applyApproverChangesForDraft = async (
    selections: readonly MedtechUserV2[],
  ) => {
    const { selectedApproverIds } = getApproverChanges(selections);
    const documentPatch: QualioDocumentPatch =
      buildAuditGroupPatch(selectedApproverIds);
    try {
      await documentApi.update(documentPatch, qualioDocument.id);
      onSuccess();
    } catch (error) {
      onError();
    }
  };

  const applyApproverChangesForNonDraft = async (
    selections: readonly MedtechUserV2[],
    comment: string,
  ) => {
    const { addedApprovers, removedApprovers } = getApproverChanges(selections);
    try {
      await documentApi.updateDocumentApprovers(
        currentUser.companyId,
        qualioDocument.id,
        {
          addedUsercompanyIds: addedApprovers.map(
            ({ usercompany_id }) => usercompany_id,
          ),
          removedUsercompanyIds: removedApprovers.map(
            ({ usercompany_id }) => usercompany_id,
          ),
          comment,
        },
      );
      onSuccess();
    } catch (error) {
      onError();
    }
  };

  const updateApprovers = async (selections: readonly MedtechUserV2[]) => {
    const { addedApprovers, removedApprovers } = getApproverChanges(selections);

    if (qualioDocument.status_id === DocumentStatus.Draft) {
      setConfirmationModalProps({
        addedApprovers,
        removedApprovers,
        selections,
        onSubmit: (selections) => applyApproverChangesForDraft(selections),
      });
    } else {
      setConfirmationModalProps({
        addedApprovers,
        removedApprovers,
        selections,
        requiresComment: removedApprovers.length > 0,
        onSubmit: (selections, comment) =>
          applyApproverChangesForNonDraft(selections, comment),
      });
    }

    setIsLookupModalOpen(false);
    setIsConfirmationModalOpen(true);
    return Promise.reject(new Error());
  };

  if (!canManageApprovers) {
    return null;
  }

  return (
    <>
      <QButton
        data-cy={`document-manage-${approverType}-approvers-button`}
        variant="ghost"
        onClick={() => setIsLookupModalOpen(true)}
      >
        Manage
      </QButton>
      <QLookup.DataProvider.Fixed
        data={validApprovers}
        filterOption={filterOptionCallback}
      >
        {(isLookupModalOpen || isConfirmationModalOpen) && (
          <QLookup.MultiSelect<MedtechUserV2>
            isOpen={isLookupModalOpen}
            action="Continue"
            searchPlaceholder="Filter by name, email or group name..."
            accessors={{
              id: 'id',
            }}
            isItemDisabled={isApproverRowDisabled}
            isItemPreSelected={isCurrentApprover}
            onSelect={updateApprovers}
            onCancel={onClose}
            view={userDataView}
            defaultSortBy={{
              id: 'full_name',
            }}
            requiredSelections={approverType === 'quality' ? 1 : 0}
          >
            <QModalHeader>
              <QLookupModalComposedHeader
                title={modalTitle}
                subtitle={modalSubtitle}
              />
            </QModalHeader>
          </QLookup.MultiSelect>
        )}
      </QLookup.DataProvider.Fixed>
      <ManageApproversConfirmationModal
        {...confirmationModalProps!}
        isOpen={isConfirmationModalOpen}
        onClose={onClose}
        onPrevious={() => {
          setIsLookupModalOpen(true);
          setIsConfirmationModalOpen(false);
        }}
        modalTitle={modalTitle}
        displayWarning={isRemovingAllPendingApprovers}
      />
    </>
  );
};
