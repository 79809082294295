import { formatDate } from '../util/DateUtils';
import { qualioCommentThreadBuilder } from '../views/ckeditor/QualioCommentThreadView';
import QualioCommentView from '../views/ckeditor/QualioCommentView';
import { CKEditorConfig } from './CKEditorConfig';
import { PresenceListConfig, SidebarConfig } from './DocumentEditorConfig';

export class ContextConfigFactory {
  contextConfig: CKEditorConfig;

  constructor() {
    this.contextConfig = {};
  }

  getConfig = () => {
    return this.contextConfig;
  };

  withPlugins = (plugins: any[]) => {
    this.contextConfig.plugins = plugins;
    return this;
  };

  withCloudService = (collaborationToken: string, bundleVersion?: string) => {
    this.contextConfig.cloudServices = {
      tokenUrl: () => Promise.resolve(collaborationToken) as any,
      uploadUrl: '',
      webSocketUrl: process.env.REACT_APP_CKE_COLLAB_WS_URL,
    };
    if (bundleVersion) {
      this.contextConfig.cloudServices.bundleVersion = bundleVersion;
    }
    return this;
  };

  withToolbarItems = (toolbarItems: string[]) => {
    this.contextConfig.toolbar = {
      items: toolbarItems,
      shouldNotGroupWhenFull: false,
    };
    return this;
  };

  withSidebar = ({ sidebarElement, preventScrollOutOfView }: SidebarConfig) => {
    this.contextConfig.sidebar = {
      container: sidebarElement as any,
      preventScrollOutOfView,
    };
    return this;
  };

  withPresenceList = ({ containerElement }: PresenceListConfig) => {
    this.contextConfig.presenceList = {
      container: containerElement as any,
      displayMe: false,
      collapseAt: 4,
    };
    return this;
  };

  withContextCollaboration(channelIDSuffix: string) {
    this.contextConfig.collaboration = {
      channelId: `${process.env.REACT_APP_ENV}${
        process.env.REACT_APP_CHANNEL_ID_SUFFIX ?? ''
      }-${channelIDSuffix}`,
    };
    return this;
  }

  withComments(
    commentsPermission: number,
    documentStatus: string,
    companyId: number,
    documentId: number,
  ) {
    const commentThreadView = qualioCommentThreadBuilder(
      commentsPermission,
      documentStatus,
      companyId,
      documentId,
    );
    this.contextConfig.comments = {
      // https://ckeditor.com/docs/ckeditor5/latest/api/module_comments_comments-CommentsConfig.html
      editorConfig: {},
      CommentView: QualioCommentView as any,
      CommentThreadView: commentThreadView as any,
    };
    this.contextConfig.locale = {
      dateTimeFormat: formatDate,
    };
    return this;
  }

  withCompanyId(companyId: number) {
    this.contextConfig.companyId = companyId;
    return this;
  }

  withDocumentId(documentId: number) {
    this.contextConfig.documentId = documentId;
    return this;
  }
}
